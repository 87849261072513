<template lang="pug">
div
  div(v-if="step === 'cart'")
    van-cell-group(v-for="(c, index) of rooms"
      :key="index"
      )
      van-cell(
        :title="c.name"
        :border='false'  
        @click="toggle(index, c)"
      )
        template(#title)
          van-row
            van-col
              van-checkbox(:value='checkGroup(c)')
            van-col(offset='1')  
              .label {{c.name}}  
      van-checkbox-group(
        v-model='c.checkedGoods'
        ref="checkboxGroup"
        @change='onCheckBoxGroup'
      )
        van-swipe-cell.card-goods__item(v-for='item in c.goods', :key='item.id', )
          van-row(type="flex" justify="center" align='center')
            van-col(:span='2')
              van-checkbox(:name='item.id' ref='checkboxes' label-disabled)
            van-col(:span='22')
              van-card(
                :tag='item.tag' 
                :title='item.title', 
                :desc='item.desc', 
                :price='formatPrice(item.price)', 
                :thumb='item.thumb'
                @click-thumb="$router.push({name:'detail', query: {id: item.origin.fur_id_poi_furnitures, sk_id: item.sku_poi_furniture_sku}})")
                template(#num)
                  van-stepper(v-model="item.num" integer button-size="26px")
                template(#footer)
                  van-button(round size='mini' type="danger" text="删除" @click="removeToCart(c, item)" style="margin-top:10px;")
          template(#right)
            van-button(square type="danger" text="删除" @click="removeToCart(c, item)" style="height:100%;")
    van-empty(v-if="rooms.length === 0" description="空空如也")
    van-submit-bar(:price='totalPrice' :disabled='!totalCheckedGoods.length', :button-text='submitBarText', @submit='onSubmit')
      van-checkbox(v-model='checked' @click ='checkAll') 全选
  div(v-if="step === 'confirm'")
    OrderConfirm(:rooms="rooms", :totalPrice="totalPrice" @onbackcart='onback')
  loading-view(:value="loadingState")
</template>

<script>
import { Checkbox, CheckboxGroup, Card, SubmitBar } from 'vant'
import {previewSize} from '../../lib/helpers/OssImageHelper'
import OrderConfirm from './orderconfirm.vue'
export default {
  components: {
    [Card.name]: Card,
    [Checkbox.name]: Checkbox,
    [SubmitBar.name]: SubmitBar,
    [CheckboxGroup.name]: CheckboxGroup,
    OrderConfirm
  },
  data() {
    return {
      loadingState: false,
      rooms: [{
        name: '',
        checkedGoods: []
      }],
      originRooms:[],
      step: 'cart',
      checked: false,
      totalPrice: 0,
    };
  },

  computed: {
    submitBarText() {
      const count = this.totalCheckedGoods.length
      return '结算' + (count ? `(${count})` : '')
    },
    totalCheckedGoods () {
      let totalGoods = this.rooms.reduce((total, {checkedGoods}) => {
        if (!total) total = []
        checkedGoods = checkedGoods ? checkedGoods : []
        return total.concat(checkedGoods)
      }, [])
      return totalGoods
    },
    companyInfo(){
      return this.$store.state.companyInfo
    },
    sellerId() {
      return this.$store.state.userInfo.seller_poi_sell_users
    },
  },
  methods: {
    checkAll() {
      this.$refs.checkboxGroup.map(p=> p.toggleAll(this.checked))
    },
    toggle(index, c) {
      let check = c.checkedGoods.length == c.goods.length
      this.$refs.checkboxGroup[index].toggleAll(!check)
    },
    onCheckBoxGroup() {
      this.totalPrice = 0
      let selectall = true
      this.rooms.map( ({checkedGoods, goods,}) => {
        if(checkedGoods.length != goods.length) {
          selectall = false
        }
        if (goods && Array.isArray(goods)) {
         goods.map(({id, price,num}) => {
            this.totalPrice += (checkedGoods.indexOf(id) != -1 ? parseInt(price * 100) * parseFloat(num) : 0)
          })
        } 
      })
      this.checked = selectall
    },
    async getListData() {
      this.loadingState = true
      let params = {
        com_id_poi_companys : this.companyInfo.id,
        st_id_poi_company_stores : (this.$store.state.userInfo.sell_users || {}).st_id_poi_company_stores || 0,
        seller_poi_sell_users: this.sellerId
      }
      let res = await this.$api.GetCart(params)
      this.rooms = res.data.items.map((room) => {
        room.name = ''
        room.checkedGoods = []
        room.goods = room.category_rel_cart_items.map((good) => {
          // 价格从 sku 的 price 中读取
          let {color, size, version, price} = good.furniture_sku
          return {
            id: good.furniture_sku.sk_id,
            title: good.furnitures.fur_name,
            desc: `${color} ${size}`,
            price: price,
            num: good.num,
            thumb: good.furniture_sku.thumbnail + previewSize,
            origin: good,
            checkedGoods: [],
            mask_delete: good.mask_delete,
            tag: ''  // 暂时不处理失效商品
            // tag: good.mask_delete === 0 ? '': '失效'
          }
        })
        return room
      })
      this.loadingState = false
    },
    formatPrice(price) {
      return (price/1).toFixed(2);
    },
    onSubmit() {
      this.originRooms = this.rooms
      this.step = 'confirm'
      // this.showPopover = true
    },
    // 选择删除
    async removeSelectToCart() {
      if (this.checkedGoods.length > 0) {
        let selectIds = this.checkedGoods.join(',')
        await this.$api.RemoveToCart(selectIds)
      }
    },
    // 删除某一项
    async removeToCart(c, item) {
      this.$dialog.confirm({
        title: '',
        message: '移除商品',
      })
      .then(async () => {
        try {
            let data = 'ids=' + item.origin.id
            let res = await this.$api.RemoveToCart(data)
            c.goods = c.goods.filter(p=> p != item)
            c.checkedGoods = c.checkedGoods.filter(p => p != item.id)
            this.$toast('删除成功')
          } catch(ex) {
            this.$toast(this.$error(ex))
          }
      })
      .catch(() => {
        // on cancel
      })
    },
    // 返回到购物车
    onback() {
      this.rooms=[{
        name: '',
        checkedGoods: []
      }],
      this.step = 'cart'
      this.rooms =JSON.parse( JSON.stringify(this.originRooms))
    },
    checkGroup(c) {
      return c && c.checkedGoods && c.goods && c.checkedGoods.length==c.goods.length
    },
  },
  async mounted() {
    try {
      await this.getListData()
    } catch (e) {
      this.$toast(this.$error(e))
    }
    this.$emit('on-nav', {
      leftText: '返回',
      leftArrow: true,
      onClickLeft: () => {
        if(this.step === 'confirm') {
          this.onback()
        }else{
          this.$router.back(-1)
        }
      },
      rightText: '主页',
      rightArrow: true,
      onClickRight: () => {
        this.$router.push({name: 'home'})
      }
    })
  }
}
</script>

<style lang="less">
.card-goods {
  padding: 10px 0;
  background-color: #fff;
  &__item {
    position: relative;
    background-color: #fafafa;
    .van-checkbox__label {
      width: 100%;
      height: auto; // temp
      padding: 0 10px 0 15px;
      box-sizing: border-box;
    }
    .van-checkbox__icon {
      top: 50%;
      left: 10px;
      z-index: 1;
      position: absolute;
      margin-top: -10px;
    }
    .van-card__price {
      color: #f44;
    }
  }
}
</style>