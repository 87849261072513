<template lang="pug">
div
  //- van-cell-group(title="收货地址" @click="onSelectAddress()")
    template(#title)
      span( @click="onSelectAddress()") 收货地址
    van-cell(center @click="onSelectAddress()")
      template(#title)
        span {{address.address}}
      template(#label)
        | {{address.name}} {{address.tel}}
  PickerAddressSelect(v-model="address" label="收货地址" label-width='70px' placeholder="请选择送货地址" type='datetime')
  PickerData(v-model="delivery_time" label="送货日期" placeholder="请选择送货日期" type='datetime')
  PickerField(label='结算方式' state_type='order_pay_type' v-model='order_pay_type') 
  PickerFieldCommon(label='是否开票' :options='fapiaoOptions' v-model='has_bill') 
  van-cell-group(title="商品信息")
    van-swipe-cell(v-for='item in dataArr' :key='item.id' stop-propagation)
      van-card(      
        :name='item.id'
        :title='item.name', 
        :desc= 'item.desc', 
        :num='item.num', 
        :price='item.price', 
        :thumb='item.thumb'
        @click-thumb="item.fur_id_poi_furnitures>0 ? $router.push({name:'detail', query: {id: item.fur_id_poi_furnitures, sk_id: item.sku_poi_furniture_sku}}) : () => {$toast('非库存商品无法查看')}"
      )
        template(#num)
          van-stepper(v-model="item.num" integer button-size="26px")
        //- template(#price v-if='fields_data.is_factory_quote == "y"')
        //-   van-row
        //-     van-col
        //-       span(style='color:red') 销售价： ￥{{item.price}}
        //-   van-row
        //-     van-col
        //-       span(style='color:blue') 出厂价： ￥{{item.factory_price}}  
        template(#price)
          //- span 销售价：
          //- span(style='color:red') ￥{{item.price}}
          span(style='margin-left:20px;') 经销价：
          span(style='color:red') ￥{{item.factory_price}}
          span(v-if='item.factory_favor_amount && item.factory_favor_amount > 0' style='margin-left:20px;') 折扣价：
          span(v-if='item.factory_favor_amount && item.factory_favor_amount > 0' style='color:red') ￥{{item.factory_discount_price}}
      template(#right='')
       van-button(square='' type='danger' text='删除' style="height:100%;" @click="onDelete(c, item)" )
        
  van-submit-bar(:button-text='submitBarText' :price="cartPrice" @submit='onSubmit' :disabled='dataArr.length==0' :label='commitText') 
    //- template(#default)
      span {{ cartPrice }} {{ commitText }}
  van-action-sheet(v-if='showAddress'  v-model='showAddress', title='选择地址')
    addr-list(@onAddressChange="onAddressChange")
  loading-view(:value="loadingState")
</template>

<script>
import { 
  SubmitBar, 
} from 'vant'

import formmixin from '../../components/form'
import AddressList from '../../views/hall/_AddressList'
import {previewSize} from '../../lib/helpers/OssImageHelper'
import * as dayjs from 'dayjs'
let promise = null
export default {
  mixins: [formmixin],
  props: [
    'rooms'
  ],
  components: {
    [SubmitBar.name]: SubmitBar,
    'addr-list':AddressList,
  },
  data() {
    return {
      loadingState: false,
      previewSize,
      commitState: false,
      showAddress: false,
      quoteData: {
        title: '',
        code: '',
        remark: '',
        cus_com_poi_ofc_cus_companys: 0,
        company_name: '',
        receipt: '',
        tel: '',
        address: '',
        factory_discount_price: 0,      // 出厂价
        factory_total_price: 0          // 产品价格
      },
      dataArr: [],
      order_pay_type: '',   // 结算方式
      address: {},    // 当前选择的收货地址
      delivery_time: '',
      has_bill: '',
      submitBarText: '提交订单',
      favorConfig: [],
      fapiaoOptions: [
        {
          label: '是',
          value: '1'
        },
        {
          label: '否',
          value: '0'
        }
      ]
    };
  },
  computed: {
    totalPrice() {
      const price = this.dataArr.reduce((pre,cur) => {
        return pre + (cur.num * cur.price)
      },0)
      return price
    },

    favor_amount() {
      // const price = this.order_pay_type && this.order_pay_type == 'now' ? (this.totalPrice * this.pay_type_favor_rate / 100).toFixed(2) : 0
      // return price
      return 0
    },

    discount_price() {
      return 0
      // return this.totalPrice - this.favor_amount
    },

    factory_totalPrice() {
      const price = this.dataArr.reduce((pre,cur) => {
        return pre + (cur.num * cur.factory_discount_price)
      },0)
      return price
    },

    factory_favor_amount() {
      let price = 0
      if (this.order_pay_type && this.order_pay_type == 'now') {
        price = (this.factory_totalPrice * this.pay_type_favor_rate / 100).toFixed(2)
      }
      if (this.has_bill == '0' && this.no_bill_favor_rate > 0) {
        price = (parseFloat(price) + (this.factory_totalPrice - parseFloat(price)) * this.no_bill_favor_rate / 100).toFixed(2)
      }
      return parseFloat(price)
    },

    // 订单总优惠
    factory_discount_price() {
      return this.factory_totalPrice - this.factory_favor_amount
    },

    // 产品优惠金额
    product_favor_amount() {
      const price = this.dataArr.reduce((pre,cur) => {
        return pre + (cur.num * cur.factory_favor_amount)
      },0)
      return price
    },

    // 按结款方式优惠
    pay_type_favor_rate() {
      if (this.order_pay_type) {
        const findItem = this.favorConfig.find(p => p.relation_type == this.order_pay_type && p.type == '1')
        return findItem ? parseFloat(findItem.favor_rate) : 0
      }
      return 0
    },

    // 不开票优惠
    no_bill_favor_rate() {
      const findItem = this.favorConfig.find(p => p.relation_type == 'has_bill' && p.type == '2')
      return findItem ? parseFloat(findItem.favor_rate) : 0
    },

    cartPrice() {
      const res = (this.factory_discount_price * 100)
      return res
    },

    commitText() {
      if (this.factory_favor_amount > 0) {
        // return `产品金额：${this.factory_totalPrice} 现结优惠：${this.factory_favor_amount} 合计：`
        return `订单优惠：${this.factory_favor_amount} 合计：`
      }
      return '合计'
    },

    factory_favor_reason() {
      let reason = ''
      const product_favor_amount = this.product_favor_amount
      if (product_favor_amount > 0) {
        reason = `产品优惠：${this.product_favor_amount}`
      }
      if (this.order_pay_type && this.order_pay_type == 'now') {
        reason = reason + ` 现结优惠：${this.pay_type_favor_rate}%`
      }

      if (this.has_bill == '0') {
        reason = reason + ` 不开票优惠：${this.no_bill_favor_rate}%`
      }
      return reason
    }
  },

  methods: {
    
    // 格式化item数据
    async initDataArr() {
      let params = {
        where: {
          com_id_poi_companys: this.$store.state.routeInfo.comId,
        }
      }
      if (!promise) {
        promise = this.$api.GetFavorAmountConfig(params)
      }
      const favorRes = await promise
      this.favorConfig = favorRes.data.items.map(p => {
        return {
          ...p,
          type: parseInt(p.type),
          min_num: parseFloat(p.min_num),
          max_num: parseFloat(p.max_num),
          favor_rate: parseFloat(p.favor_rate),
        }
      })
      let newId = 0
      if (this.rooms.length > 0) {
        const { checkedGoods, goods } = this.rooms[0]
        const selectGoods = goods.filter(p => checkedGoods.indexOf(p.id) != -1)
        this.dataArr = selectGoods.map(p => {
          const factory_price =  parseFloat(p.origin.furniture_sku.factory_price)
          const cost_price =  parseFloat(p.origin.furniture_sku.cost_price)
          const discount_rate = parseFloat(p.origin.furniture_sku.discount_rate)
          const factory_discount_price = Math.round(factory_price * discount_rate / 100)
          const factory_favor_amount = factory_price - factory_discount_price
          
          const cost_discount_price = Math.round(cost_price * discount_rate / 100)

          return {
            id: newId--,
            desc: p.desc,
            thumb: p.thumb,
            thumbnail_url: p.origin.furniture_sku.thumbnail,
            name: p.title,
            fur_name: p.origin.furnitures.fur_name,
            price: parseFloat(p.price),
            num: parseFloat(p.num),
            fur_id_poi_furnitures: p.origin.fur_id_poi_furnitures,
            sku_poi_furniture_sku: p.id,
            factory_price,
            factory_favor_amount,
            factory_discount_price,
            cost_discount_price,
            cost_price: parseFloat(p.origin.furniture_sku.cost_price),
            subtotal: (parseFloat(p.num) * parseFloat(p.price)).toFixed(2),
            sizes: p.origin.furniture_sku.size,
            color: p.origin.furniture_sku.color,
            code: p.origin.furnitures.fur_code,
            unit: p.origin.furniture_sku.unit,
            discount_rate,
          }
        })
      }
    },

    onDelete(array, obj) {
      this.$dialog.confirm({
        title: '',
        message: '是否删除商品？',
      }).then(async () => {
        try {
          this.dataArr = this.dataArr.filter(p => p != obj)
        } catch(ex) {
          this.$toast(this.$error(ex))
        }
      })
      .catch(() => {})
    },

    onSelectAddress() {
      this.showAddress = true
    },

    onAddressChange(obj) {
      this.address = obj
      this.showAddress = false
    },

    async onSubmit() {
      try {
        if(!this.address || !this.address.receipt) {
          this.$toast('请选择收货地址')
          return 0
        }
        if(this.dataArr.length == 0) {
          this.$toast('下单不能为空')
          return 0
        }
        if(!this.order_pay_type) {
          this.$toast('请选择结算方式')
          return 0
        }
        if(!this.has_bill) {
          this.$toast('请选择是否开票')
          return 0
        }
        let data = {
          receipt: this.address.receipt,
          order_pay_type: this.order_pay_type,
          tel: this.address.tel,
          address: this.address.address,
          has_bill: this.has_bill,
          delivery_self: this.address.self,
          delivery_time: this.delivery_time,
          total_price: this.totalPrice.toFixed(2),
          discount_price: this.discount_price.toFixed(2),
          factory_total_price: this.factory_totalPrice.toFixed(2),
          factory_discount_price: this.factory_discount_price.toFixed(2),
          factory_favor_amount: this.factory_favor_amount,
          favor_amount: this.favor_amount,
          factory_favor_reason: this.factory_favor_reason,
          items: this.dataArr.map(p => {
            return {
              ...p,
              subtotal: (p.num * p.factory_discount_price).toFixed(2),
              factory_price: p.factory_discount_price,
              cost_price: p.cost_discount_price,
              id_poi_scm_delivery_type: this.address.id_poi_scm_delivery_type,
            }
          })
        }
        this.commitState = true
        // 这里去创建订单
        // console.log(data)
        await this.$api.CreateAppOrder(data)
        this.$toast('下单成功!')
        this.$router.push({name: 'order' })
      } catch (e) {
        this.$toast(this.$error(e))
      }
      this.commitState = false
    },
  },
  async mounted() {
    // 默认一个月工期
    this.delivery_time = dayjs().add(15,'day').valueOf()
    this.initDataArr()
  }
};
</script>

<style lang="less">
.card-goods {
  padding: 10px 0;
  background-color: #fff;
  &__item {
    position: relative;
    background-color: #fafafa;
    .van-checkbox__label {
      width: 100%;
      height: auto; // temp
      padding: 0 10px 0 15px;
      box-sizing: border-box;
    }
    .van-checkbox__icon {
      top: 50%;
      left: 10px;
      z-index: 1;
      position: absolute;
      margin-top: -10px;
    }
    .van-card__price {
      color: #f44;
    }
  }
}
</style>